.UnorderedList {
    list-style: none;
    padding: 0;
}

.text_color {
    color: #183696;
}

@media only screen and (max-width: 700px) {
    .divReporting {
        flex-direction: column-reverse;
    }
}