.UnorderedList {
    list-style: none;
    padding: 0;
}

.text_color {
    color: #183696;
}

@media only screen and (max-width: 700px) {
    .FeaturesTrackImage {
        display: none;
    }

    .FeaturesTrackDiv {
        width: 100% !important;
    }
}
.line_height{
    line-height: 1.8;
}