.businessInfoCards {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 98%;
}

.businessInfoCard {
    display: flex;
    width: 20rem;
    margin-block: 2rem;
    padding: 1.5rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    justify-content: space-between;
}

.groupName {
    display: flex;
    width: fit-content;
    margin-block: 2rem;
    padding: 1.5rem 3rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    justify-content: space-between;
}

.groupName>h3 {
    font-size: 2rem;
    margin: auto;
    color: #183696;
    font-weight: 700;
}

.createGroupCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-block: 2rem;
    margin-right: 2rem;
    padding: 1.2rem 0.7rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    background: #183696;
    border-radius: 8px;
}

.createGroupCard h3 {
    font-size: 1.2em;
    color: #FFFFFF;
    font-weight: 700;
}

.businessNumbers {
    display: flex;
    flex-direction: column;
}

.businessNumbers>h2 {
    align-self: center;
}

.businessIcon {
    font-size: 3.5rem !important;
    border-radius: 50%;
    background: #183696;
    color: white;
    margin-block: auto;
}

.filterContainer {
    background: #FFFFFF;
    height: 6.3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    /* border */
    border-width: 0.96px 0.96px 0.96px 0;
    border-style: solid;
    border-color: #F6F6F6;
}

.groupFilter h6 {
    color: #201D23;
    font-weight: 400;
}

.filterBtn {
    border: 0.96px solid #F6F6F6;
    width: 16rem;
    height: 3rem;
    padding: 0.9rem;
    border-radius: 4px;
}

.groupSearchContainer {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 1.2rem;
    border-radius: 3.83px;
    border-width: 0.96px 0.96px 0.96px 0px;
    border-style: solid;
    border-color: #F6F6F6;
    padding-block: 1.5rem;
    margin-block: 1rem;
    box-shadow: 0px 3.8322579860687256px 57.48387145996094px 0px rgba(0, 0, 0, 0.1);
}

.searchContainer {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-block: 1.2rem;
}

.searchField {
    width: 30% !important;
    height: 2.4rem;
    padding: 0.60125rem 0.96125rem 0.60125rem 0.96125rem !important;
    margin-bottom: 2rem;
    font-size: 0.85rem !important;
    color: #201D2380 !important;
    background-color: #E3E3E34D !important;
}

.searchField:focus {
    border-color: unset !important;
    box-shadow: unset !important;
}

.clearTableBtn {
    border: 0.96px solid #F3F3F3;
    border-radius: 50%;
    padding: 0.6rem;
    height: fit-content;
    margin-right: 3rem;
    color: #183696;
    font-weight: 700;
}

.formSubmitBtn {
    border: 0.96px solid #F3F3F3;
    height: 3rem;
    padding: 0.9rem;
    padding-inline: 2.5rem;
    border-radius: 4px;
    margin-right: 3rem;
    font-weight: 600;
}

.progress {
    display: flex;
    height: 3rem;
    justify-content: center;
}

.certificate {
    display: flex;
    justify-content: center;
    border: 1px solid #1A932E2E;
}

.circle {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.textSuccess {
    background-color: #1A932E2E;
    color: #1A932E;
}

.textDanger {
    background-color: #EE201C2E;
    color: #EE201C;
}

.tableHeaders {
    color: #183696 !important;
    text-align: center;
}

.groupCardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem, ;
}

.groupCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 7rem;
    margin: 1.5rem;
    padding: 1.35rem;
    box-shadow: 4px 4px 4rem 0px rgba(0, 0, 0, 0.1);
    border-radius: .6rem;
    cursor: pointer;
    transition: 0.2s;
}

.groupCard:hover {
    transform: translateY(-2%);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.groupCard>h3 {
    font-size: 1.2em;
    color: #183696;
    font-weight: 700;
}

.deleteUserBtn {
    border: 0;
    padding: 0;
    color: #EE201C;
    text-decoration: underline;
}

.progressAnchor {
    font-size: .7rem;
    font-weight: 600;
    color: white;
}