.businessSwiper {
    text-align: center;
    width: 55%;
    margin-block: 1.5rem;
}

.businessSwiper img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.achievementSec {
    display: flex;
    gap: 1.5rem;
    margin-block: 4rem;
    align-items: center;
}

.goalsAchieved h3 {
    color: #183696;
    font-weight: 700;
    font-size: clamp(1.3rem, 3.5vw, 2.2rem);
}

.goalsAchieved h6 {
    margin-block: .8rem;
}

.reviews p {
    margin-block: 1.1rem;
    margin-left: 3rem;
}

.personReview {
    display: flex;
    gap: 1.2rem;
}

.personReview h4 {
    color: #183696;
    font-weight: 700;
    font-size: 1.4rem;
}

.requestQuoteSec {
    background: url(../../assets/Business/Group\ 48.jpeg) no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 1.5rem;
    gap: 0.7rem;
    background-size: cover;
}

.requestQuoteSec h2 {
    color: #183696;
    font-weight: 700;
    margin-top: 2.5rem;
    font-size: clamp(1.5rem, 1.7vw, 1.8rem);
}

.requestQuoteSec h6 {
    font-size: 0.85rem;
    font-weight: 500;
    color: #1E1E1E;
}

.requestBtn {
    color: #F3F3F3;
    background-color: #183696;
    font-size: 1rem;
    font-weight: 700;
    border: 0;
    padding: 0;
    border-radius: 4px;
    text-align: center;
    padding: 3.5rem;
    padding-block: .8rem;
    margin-bottom: 1rem;
}

.moreCustomersBtn {
    background: #FFFFFF !important;
    color: #1E1E1E !important;
    border: 1px solid #1E1E1E !important;
    display: flex !important;
    margin: auto !important;
    border-radius: 4px !important;
    padding: 0.8rem 3.5rem !important;
}

@media screen and (max-width: 40rem) {
    .businessSwiper {
        width: 75%;
    }

    .achievementSec {
        flex-wrap: wrap;
    }
}

.reviews {
    width: 40%;

}

.goalsAchieved_1 {
    width: 50%;

}

.goalsAchieved_1 h3 {
    color: #183696;
    font-weight: 700;
    font-size: clamp(1.3rem, 3.5vw, 2.2rem);
}

.goalsAchieved_1 h6 {
    margin-block: .8rem;
}

.Customer_Images {
    width: 20%;
}

