.username-button {
    margin-top: 16px !important;
    color: black;
}

.username-buttonTWO {
    margin-top: 16px !important;
    color: black;
}

.sign-out-btn {
    background: none;
    border: none;
}

.sign-out-btn:hover {
    border-radius: 3px !important;
    color: black !important;
}

.main-nav-padding {
    padding: 0px !important;
}

.logo--image {
    cursor: pointer;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    background: #f3f3f3;
}

.logo-teams-width {
    width: 5.2rem !important;
}

@media only screen and (max-width: 768px) {
    .main-nav-responive {
        width: 70% !important;
    }
}