.big_section {
    display: flex;
    min-height: 90vh;
}

.contactUs {
    background: url(../../assets/Business/Card\ BG.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    width: 100%;
}

.contactUs h1 {
    font-size: clamp(1.3rem, 3.5vw, 2.3rem);
    color: #1E1E1E;
    font-weight: 700;
    margin: auto;
    padding-inline: 2rem;
    line-height: clamp(2.5rem, 5vw, 4rem);
}

.contactUs>h1>strong {
    color: #183696;
}

.sectionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#leftSec {
    padding: 2rem;
    margin-top: 6rem !important;
}

.noMargin {
    margin-bottom: 0px;
    font-size: 1rem;
}

.inputContainer {
    margin-bottom: 1rem;
}

.inputWidth {
    width: 90%;
    /* border: 0.81px solid #E2E5EC !important; */
    margin-bottom: 10px !important;
    border-radius: 3.25px;
}

.combinedField {
    display: flex;
    gap: 2rem;
    width: 90%;
}

.formButtons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    max-width: 90%;
}

.businessSubmitBtn {
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #FFFFFF;
    padding: .75rem 5rem !important;
}


@media screen and (max-width:768px) {
    .inputWidth {
        width: 100%;
    }

    .inputContainer {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .combinedField {
        width: 100%;
        gap: .5rem;
    }
}

@media screen and (max-width:991px) and (min-width:769px) {
    .inputWidth {
        width: 18ch;
    }
}

@media screen and (max-width:767px) {
    .sectionsContainer {
        flex-direction: column;
    }

    #leftSec {
        width: 100%;
    }
}

@media screen and (max-width:34rem) {
    .big_section {
        flex-direction: column;
        /* margin-top: 11rem; */
    }

    .contactUs>h1 {
        margin-top: 5.5rem;
    }

    .formButtons {
        max-width: unset;
    }

    #leftSec {
        margin-top: 1rem !important;
    }
}