.breadcrumbsColor {
    color: #4658AC !important;
}

.GroupNameFontSize {
    font-size: 1.5rem;

}

.box {
    width: 354.941px;
    padding: 25.433px;
    border-radius: 12.716px;
    background: var(#FFF);
    box-shadow: 0px 0px 50.866px 0px rgba(0, 0, 0, 0.07);
}

.user_personal_data {
    background: #FFF;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.Enrolled_Courses_Title {
    color: #4658AC;
    padding: 0 !important;
    font-size: 1.4rem;
}

.tapBackgroundColor {
    color: #4658AC !important;
}