.dashboardContainer {
    margin: 0;
    font-size: 1rem;
    background: #FFFFFF;
    display: grid;
    grid-template-columns: 14rem 1fr;
    gap: 2rem;
}

.tabsContainer {
    position: sticky;
    top: 0;
    max-height: 125svh;
    background: #F3F3F3;
}

.tabsNavigation {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    gap: 2rem;
    padding-block: 5rem;
    padding-left: 1.3rem;
}

.navigateContainer {
    color:#183696 !important;
    font-family: Tajawal;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.375rem;
    padding: 0.7rem;
    border-radius: 100vw 0 0 100vw;
}

a.active {
    background: #FFFFFF;
    position: relative;
}

a.active::before,
a.active::after {
    --border-radius: 1.5rem;

    content: '';
    position: absolute;
    width: var(--border-radius);
    height: var(--border-radius);
    right: 0;
    background: #F3F3F3;
}

a.active::before {
    top: calc(var(--border-radius)* -1);
    border-radius: 0 0 100vw 0;
    box-shadow: 10px 10px 0 10px #FFFFFF;
}

a.active::after {
    bottom: calc(var(--border-radius)* -1);
    border-radius: 0 100vw 0 0;
    box-shadow: 10px -10px 0 10px #FFFFFF;
}

.navItem {
    display: flex;
    align-items: center;
    gap: .5rem;
    padding-left: 1rem;
}

.navItem img {
    width: 2.1rem;
}

.navItem p {
    margin-top: 1.1rem;
}
