.serviceStudio {
    background: url(../../assets/Business/serviceClass.jpeg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 1.2rem;
}

.serviceStudioIntro {
    width: 55%;
}

.serviceStudioIntro h2 {
    color: #EE4036;
    font-weight: 700;
    font-size: clamp(1rem, 3.5vw, 3rem);
    padding-bottom: clamp(0.4rem, 1.85vw, 1rem);
    margin-bottom: 0;
}

.serviceStudioIntro>img {
    width: clamp(55%, 6vw, 70%);
}

.serviceStudioIntro p {
    color: #1e1e1e;
    font-weight: 400;
    font-size: clamp(0.55rem, 1.7vw, 1.4rem);
    padding-top: clamp(0.4rem, 1.5vw, 1rem);
    margin-bottom: 1rem;
}

.requestBtn {
    color: #f3f3f3;
    background-color: #183696;
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    text-align: center;
    padding: 1em 2em;
}


.commonArticle {
    display: flex;
    gap: 2rem;
    margin-inline: 2rem;
    margin-block: 1.5rem;
}

.commonArticle img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.interactiveArticle {
    gap: clamp(1rem, 9vw, 5.5rem);
}

.serviceHeader {
    font-weight: 700;
    color: #183696;
    font-size: clamp(1.1rem, 3vw, 1.9rem);
    line-height: clamp(1rem, 3.5vw, 3rem);
    margin-bottom: clamp(0.7rem, 1.8vw, 1.25rem);
}

.serviceSubHeader {
    color: #1e1e1e;
    font-size: 1.1rem;
    margin-block: 1rem;
}

.serviceStudioLists li {
    list-style-image: url(../../assets/Business/Group.svg);
    margin-left: 1rem;
    padding-block: 0.4rem;
}

.customizedPrograms {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-inline: 1rem;
    align-items: center;
}

.customizedPrograms>img {
    margin-block: 1.5rem;
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.contentLibraries {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
}

.contentLibraries>h2 {
    color: #183696;
    font-weight: 700;
    font-size: clamp(1.1rem, 3vw, 1.9rem);
    line-height: clamp(2.5rem, 6vw, 5rem);
    min-width: 40%;
    margin-right: 3.5rem;
}

.contentLibraries>h2>strong {
    color: #EE4036;
}

.cardDetailsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.smoothExperience {
    flex-direction: row-reverse;
}

.cardsDetail {
    border: 1.5px solid #EDEDED;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 11rem;
    border-radius: .5rem;
    padding: 1.5rem .6rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardsDetail h6 {
    font-size: .85rem;
    padding-top: 1rem;
    text-align: center;
}

@media screen and (max-width: 55rem) {
    .interactiveArticle {
        flex-direction: column;
    }

    .contentLibraries {
        flex-direction: column;
        gap: 2rem;
    }

    .smoothExperience {
        flex-direction: column;
    }
}

@media screen and (max-width: 24.6rem) {
    .cardDetailsContainer {
        justify-content: center;
    }

    .cardsDetail {
        width: 13rem;
        padding: 1.5rem;
    }
}