.businessInfoCard {
    display: flex;
    width: 20rem;
    margin-block: 2rem;
    padding: 1.5rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
}

.businessNumbers {
    display: flex;
    flex-direction: column;
}

.businessNumbers>h2 {
    align-self: center;
}

.logoCard {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    color: white;
}