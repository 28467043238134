.introBackground {
    background-image: url('../../../assets/Business/introImageTeams.png');
    height: 95vh;
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 28px;
    padding-top: 3rem;
}

.BackgroundIntroDivMobile {
    width: 65% !important;
}

.firstSentences {
    font-weight: bold;
    font-size: 45px !important;
}

.introP {
    font-size: 18px;
    font-weight: 400;
}

@media only screen and (max-width: 768px) {
    .firstSentences {
        font-weight: bold;
        font-size: 2.2rem !important;
    }
}

@media only screen and (max-width: 480px) {
    .BackgroundIntroDivMobile {
        width: 100% !important;
    }

    .introBackground {
        line-height: 32px;
        height: 78vh !important;
    }

    .firstSentences {
        font-size: 25px !important;
    }
}