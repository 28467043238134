.nav--bar {
  z-index: 99999;
  position: relative !important;
}

.navbar {
  z-index: 99999;
  background-color: black !important;
  position: fixed !important;
  transition: all .4s ease-in;
  padding: 0px !important;
}

/* .navbar a {
  color: white !important;
} */

.navbar span {
  color: white !important;
}

.profile-btn-navbar,
.username-button {
  color: black !important;
}


.nav--bar-two {
  /* padding: 10px !important; */
  position: fixed !important;
  transition: all .1s ease-in;

}

.navbar .username-button,
.navbar .profile-btn-navbar {
  color: white !important;
}

/* 
.nav--bar-two a {
  color: black !important;
  font-size: 18px !important;
} */

.btn-primary {
  background-color: #183696 !important;
  border: 1px solid #183696 !important;
  /* Add any additional styles as needed */
}

.logo--image {
  width: 65px !important;
}

.active-nav {
  text-align: center !important;
  /* margin-top: 1.5rem !important; */
  font-size: 17px !important;
  /* color: white !important; */
  text-decoration-line: underline !important;
  text-underline-offset: 10px !important;
  text-decoration-color: #183696 !important;
}

.active-nav-main {
  text-align: center !important;
  /* margin-top: 1.5rem !important; */
  font-size: 17px !important;
  /* color: white !important; */
  text-decoration-line: underline !important;
  text-underline-offset: 10px !important;
  text-decoration-color: #183696 !important;


}

.inactive {
  text-align: center !important;
  /* margin-top: 1.5rem !important; */
  font-size: 17px !important;
  /* color: white !important; */
  color: black !important;
}

.NAV {
  /* box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1) !important; */
  background-color: #F3F3F3 !important;
}

.Request-qout {
  background-color: #183696 !important;
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .active-nav {
    font-size: 12px !important;
  }

  .inactive,
  .person-icon,
  .active-nav {
    font-size: 14px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;

  }

  .navbar-responive-links {
    width: 70% !important;

  }

  .logo--image {
    width: 75% !important;
  }

  #fade-button {
    margin-right: 0 !important;
  }

}

#fade-menu ul {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

#fade-menu li {
  margin: 0.6rem 0.9rem;
}

.other-company-logo {
  width: 50px !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* Background color of the dropdown */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: black;
  border-radius: 4px;
  color: white !important;
}

.eyouthBtn {
  background: white !important;
  color: #183696 !important;
  font-weight: 400;
  font-size: 1rem;
  border: unset;
  padding: 0.41rem !important;
  border-radius: 5px;
}

.eyouthBtnDown {
  background: white !important;
  color: #183696 !important;
  font-weight: 400;
  font-size: 1rem;
  border: unset;
  padding: 0.41rem !important;
  border-radius: 5px;
}

.linkClass {
  background: white !important;
  color: #183696 !important;
  font-weight: 400;
  font-size: 1rem;
  border: unset;
}