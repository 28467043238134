.serviceStudioIntro {
    width: 55%;
}

.serviceStudio {
    background: url(../../assets/Business/serviceStudioBG.png) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 1.2rem;
    padding-top: 4rem;
}

.serviceStudioIntro h2 {
    color: #4C812B;
    font-weight: 700;
    font-size: clamp(1rem, 3.5vw, 3rem);
    padding-bottom: clamp(0.4rem, 1.85vw, 1rem);
    margin-bottom: 0;
}

.serviceStudioIntro>img {
    width: clamp(55%, 6vw, 70%);
}

.serviceStudioIntro p {
    color: #1e1e1e;
    font-weight: 400;
    font-size: clamp(0.55rem, 1.7vw, 1.4rem);
    padding-top: clamp(0.4rem, 1.5vw, 1rem);
    margin-bottom: 0.5rem;
}

.requestBtn {
    color: #f3f3f3;
    background-color: #183696;
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    text-align: center;
    padding: 1em 2em;
}

.demoBtn {
    border: 0;
    padding: 0;
    color: #1e1e1e;
    background-color: transparent;
    margin-inline: 1.4rem;
    font-weight: 400;
    font-size: 1rem;
}

.commonArticle {
    display: flex;
    gap: 2rem;
    margin-inline: 2rem;
    margin-block: 1.5rem;
}

.commonArticle img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.interactiveArticle {
    gap: clamp(1rem, 9vw, 5.5rem);
}

.serviceHeader {
    font-weight: 700;
    color: #183696;
    font-size: clamp(1.3rem, 3.5vw, 2.3rem);
    margin-bottom: clamp(0.7rem, 1.8vw, 1.25rem);
}

.serviceSubHeader {
    font-weight: 700;
    color: #000000;
    font-size: 1rem;
    margin-block: 1rem;
}

.serviceStudioLists li {
    list-style-image: url(../../assets/Business/Group.svg);
    margin-left: 1rem;
    padding-block: 0.4rem;
}

.uniqueEducational {
    flex-direction: row-reverse;
}

.eyouthStudioArticle {
    gap: clamp(1rem, 7vw, 5.5rem);
}

@media screen and (max-width: 55rem) {
    .interactiveArticle {
        flex-direction: column;
    }

    .uniqueEducational {
        flex-direction: column;
    }

    .eyouthStudioArticle {
        flex-direction: column;
    }
}