.AddMemberToGroupContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-block: 2rem;
    margin-right: 2rem;
    padding: 2rem 0.7rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    background: #183696;
    border-radius: 8px;
    border: 0;    border: 0;

}

.AddMemberToGroupContainer h3 {
    font-size: 1.2em;
    color: #FFFFFF;
    font-weight: 700;
}

.btnToAddMembers {
    border: 0;
    padding: 0;
    background: none;
}

.selectAGroup {
    min-width: 10% !important;
}