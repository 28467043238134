.whyContainer {
    background: url(../../assets/Business/whyService.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 3rem 2rem;
}

.whyDescription {
    text-align: center;
    padding-bottom: 2rem;
}

.whyDescription>h2 {
    color: #183696;
    font-size: 2.25rem;
}

.whyDescription>h5 {
    font-size: 1.125rem;
    color: #000000CC;
}

.cardDetailsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.cardsDetail {
    border: 1.5px solid #EDEDED;
    background-color: #FFFFFF;
    width: 12.5rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: .5rem;
    padding: 2rem 1.2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardsDetail h6 {
    font-size: .9rem;
    padding-top: 1rem;
}