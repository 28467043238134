.box {
    box-shadow: 0px 2px 4px 0px #00000024;
    border-radius: 20px;
    padding: 10px;
}

.color_text {
    color: #183696;
}

.featuresDataStyle {
    width: 30% !important;
}

.WhyEYouthTeamsBackground {
    background-image: url('../../../assets/Business/Shape.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

}

@media only screen and (max-width: 700px) {

    .featuresDataStyle,
    .featuresDataStyle img {
        width: 100%;
    }

}